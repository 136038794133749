import React from 'react'
import styles from '../css/main.css'


const RecipeForm = ({
	form,
	setForm,
	handleSubmit,
	handleIngredient,
	handleIngredientCount,
	handleStep,
    handleStepCount,
    setPopupActive,
    resetForm,
    handleEditSubmit,
    handleImageChange
 }) => {



	console.log(form)
    console.log(form.ingredients);

	return (


		<>
		<h2 className="text-lg font-semibold text-gray-700 capitalize dark:text-white"> Add a new recipe</h2>


<form onSubmit={handleSubmit} className="
max-w-4xl p-6 mx-auto bg-white rounded-md shadow-md dark:bg-gray-400">

		<div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-1">

          {/* TITLE */}
            <div className="form-group">
              <label className="text-gray-700 dark:text-gray-200">Title</label>
              <input type="text" 
              value={form.title} 
              onChange={e => setForm({...form, title: e.target.value})} 
              className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md 
              dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 
              focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"/>
            </div>

          {/* DESCRIPTION */}
            <div className="form-group">
              <label>Description</label>
              <textarea type="text" 
              value={form.desc} 
              onChange={e => setForm({...form, desc: e.target.value})} 
              className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md 
              dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 
              focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"/>
            </div>

            <div className="form-group">
              <label>Foto</label>
              <input type="file" accept="image/*" onChange={handleImageChange} />
            </div>
            

          {/* INGREDIENTS */}
            <div className="form-group">
              <label>Ingredients</label>
              {
              	
                form.ingredients?.map((ingredient, i) => (
                  <input type="text" 
                  key={i}
                  value={ingredient} 
              onChange={e => handleIngredient(e, i)} 
              className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md 
              dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 
              focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"/>

                  ))
              }
              <button type="button" className="px-5 py-2.5 leading-5 text-white transition-colors duration-300 
              transform bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600" onClick={handleIngredientCount} > Add ingredient </button>
            </div>

            {/* STEPS */}
            <div className="form-group">
              <label>Steps</label>
              {
                form.steps.map((step, i) => (
                  <textarea type="text" 
                  key={i}
                  value={step} 
              onChange={e => handleStep(e, i)} />

                  ))
              }
              <button type="button" onClick={handleStepCount} className="px-5 py-2.5 leading-5 text-white transition-colors duration-300 
              transform bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600" > Add step </button>
            </div>

            <div className="buttons">
              
              <button type="button" className="remove"
              onClick={function(){setPopupActive(false); resetForm()}   }>Close</button>
            </div>


            <div class="flex justify-end mt-6">
              <button type="button" className="px-5 py-2.5 leading-5 text-white transition-colors duration-300 
              transform bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600" onClick={handleEditSubmit}>Save</button>
              <button type="submit" className="px-5 py-2.5 leading-5 text-white transition-colors duration-300 
              transform bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600">Add recipe</button>
            </div>
              </div>

          </form>
		</>
	)
}

export default RecipeForm