import { db } from './firebase.config';
import { useState, useEffect } from 'react';
// collection: get db row or table, snapshot: real time edit, autoupdate live
import { collection, onSnapshot, doc, setDoc, addDoc, updateDoc, getDoc, deleteDoc, arrayUnion} from "firebase/firestore";
import Recipe from './components/Recipe'
import RecipeForm from './components/RecipeForm'
import WeekList from './components/WeekList'
import {ref, getStorage, getDownloadURL, uploadBytes} from "firebase/storage";




function App() {

  // recipes is array, initialise empty array
  const [recipes, setRecipes] = useState([]);

  // must match firebase collection!
  const [form, setForm] = useState({
    title: "",
    desc: "",
    ingredients: [""],
    steps: [],
    imageUrl: ''
  });

  const [receptId, setReceptId] = useState({
    recept: ""
  })

  const [weekList, setWeekList] = useState([]);

  const [popupActive, setPopupActive] = useState(false);
  const [weekListPopupActive, setWeekListPopupActive] = useState(false);

  const recipesCollectionRef = collection(db, "recipes");
  // const weekListCollectionRef = collection(db, "weeklist");

  const weekListRef = doc(db, "weeklist", "LiTvUruNBi8j1qH6rnzT");
  const weekListId = "LiTvUruNBi8j1qH6rnzT"

  useEffect(() => {
    onSnapshot(recipesCollectionRef, snapshot => {
      console.log(snapshot);
      setRecipes(snapshot.docs.map(doc => {
        return {
          id: doc.id,
          viewing: false,
          ...doc.data()
        }
      }));

      console.log("setRecipes = " + recipes.viewing);
    })

  onSnapshot(weekListRef, (snapshotTwo) => {
      const recipesArray = snapshotTwo._document.data.value.mapValue.fields.recipes.arrayValue.values;
      const recipeIds = recipesArray.map(obj => obj.stringValue);
    setWeekList(recipeIds);
    });
  }, []);

  function handleView(id){
   // alert(id);
    const recipesClone = [...recipes]

    recipesClone.forEach(recipe => {
      if(recipe.id === id){
        recipe.viewing = !recipe.viewing;
      } else {
        recipe.viewing = false;
      }
      })

    setRecipes(recipesClone);

  }

  const resetForm = () => {
        setForm({
      title: "",
      desc: "",
      ingredients: [],
      steps: []
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if(
      !form.title || !form.desc || !form.ingredients || !form.steps) {
      alert("Please fill out everything")
      return
    }

    addDoc(recipesCollectionRef, form);
/*
    db.collection('recipes').add(form).then((docRef) => {
      setForm({
        title: '',
        desc: '',
        ingredients: [''],
        steps: [],
        imageUrl: '',
      })
    })
    */

    // reset form
    resetForm();

    setPopupActive(false);

  }

  const handleIngredient = (e, i) => {
    const ingredientsClone = [...form.ingredients]
    ingredientsClone[i] = e.target.value

    setForm({
      ...form,
      ingredients: ingredientsClone
    })

  }

    const handleStep = (e, i) => {

    const stepsClone = [...form.steps]
    stepsClone[i] = e.target.value

    setForm({
      ...form,
      steps: stepsClone
    })

  }

  const handleIngredientCount = () => {
    setForm({
      ...form,
      ingredients: [...form.ingredients, ""]
    })
  }

    const handleStepCount = () => {
    setForm({
      ...form,
      steps: [...form.steps, ""]
    })
  }

  const removeRecipe = (id) => {
    deleteDoc(doc(db, "recipes", id));
  }

  const handleImageChange = (event) => {
     event.preventDefault();
  const file = event.target.files[0];
  if (!file) return null;

  const storage = getStorage();
  const storageRef = ref(storage, `files/${file.name}`);

  uploadBytes(storageRef, file)
    .then((snapshot) => {
      getDownloadURL(snapshot.ref).then((downloadURL) => {
        console.log(downloadURL);
        setForm({ ...form, imageUrl: downloadURL });
        // Reset the input field value after the image has been uploaded
        if (event.target && event.target[0]) {
          event.target[0].value = "";
        }
      });
    })
    .catch((error) => {
      console.error(error);
    });
  /*
  imageRef.put(file)
    .then((snapshot) => {
      console.log('Uploaded a file!');
      snapshot.ref.getDownloadURL().then((url) => {
        console.log('File available at', url);
        setForm({ ...form, imageUrl: url }); // Update the recipe object with the image URL
      });
    })
    .catch((error) => {
      console.error(error);
    });
    */
};

  const editRecipe = (id) => {
    // find the recipe being edited
  const recipeToEdit = recipes.find(recipe => recipe.id === id);
  // set the popupActive state to true to show the edit form
  setPopupActive(true);
  // populate the form state with the values of the recipe being edited
  setForm({
    title: recipeToEdit.title,
    desc: recipeToEdit.desc,
    ingredients: recipeToEdit.ingredients,
    steps: recipeToEdit.steps
  });
  // set the receptId state to the id of the recipe being edited
  setReceptId({recept: id});
  }

  // Genereer Ingrediënten

  const generateIngredients = () => {
      let ingredients = [];
      console.log(weekList.length);
      console.log(weekList);
      for (let i = 0; i < weekList.length; i++) {
        const recipeId = weekList[i];
        const recipe = recipes.find(recipe => recipeId === recipe.id);
        console.log('++++++++++');
        console.log(recipe);
        console.log('++++++++++');
        for (let j = 0; j < recipe.ingredients.length; j++) {
          ingredients.push(recipe.ingredients[j]);
        }
      }

  console.log('////////////');
  console.log(ingredients);
  console.log('////////////');

  ingredients = [...new Set(ingredients)];
  const listFinal = ingredients.join("\n");
  console.log(listFinal);
  const copyButton = document.createElement("button");
  copyButton.innerText = "Copy to Clipboard";
  copyButton.addEventListener("click", () => {
    navigator.clipboard.writeText(listFinal.text);
    console.log(listFinal)
    alert("Ingredients copied to clipboard! " + listFinal);
  });

  const shareButton = document.createElement("button");
  shareButton.innerText = "Share";
  shareButton.addEventListener("click", () => {
    const url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(listFinal)}`;
    window.open(url, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=600,height=600");
  });

  const buttonWrapper = document.getElementById("resultio");
  buttonWrapper.appendChild(copyButton);
  buttonWrapper.appendChild(shareButton);

  const modalContent = document.getElementById("resultio");
  modalContent.appendChild(document.createTextNode(listFinal));
  modalContent.appendChild(buttonWrapper);

  }

  // SAVE EDIT

  const handleEditSubmit = (e) => {
  e.preventDefault();

  if (
    !form.title ||
    !form.desc ||
    !form.ingredients ||
    !form.steps
  ) {
    alert("Please fill out everything");
    return;
  }

  // update the existing recipe with the new form data
  updateDoc(doc(db, "recipes", receptId.recept), {
    title: form.title,
    desc: form.desc,
    ingredients: form.ingredients,
    steps: form.steps
  });

  // reset the form state
  setForm({
    title: "",
    desc: "",
    ingredients: [],
    steps: []
  });

  // set the popupActive state to false to hide the edit form
  setPopupActive(false);
}

  // WEEKLIST

  const addToWeekList = (id) => {

    const weekListRef = doc(db, "weeklist", weekListId);
    updateDoc(weekListRef, {
      recipes: arrayUnion(id)
    });

  }

  // CLEAR WEEK LIST

  const clearWeekList = async () => {
  try {
    const weekListRef = doc(db, "weeklist", weekListId);
    await updateDoc(weekListRef, { recipes: [] });
    setWeekList([]);
  } catch (error) {
    console.error("Error clearing week list:", error);
  }
};

  let failSafe = 10;

  // ADD RANDOM

  const addRandomRecipe = () => {
  if (recipes.length > 0) {
    const randomIndex = Math.floor(Math.random() * recipes.length);
    const randomRecipe = recipes[randomIndex];
    if (!weekList.includes(randomRecipe.id)) {
      addToWeekList(randomRecipe.id);
    } else {
      failSafe = failSafe - 1;

      if(failSafe == 0) {
        failSafe = 10;
        return
      }
      addRandomRecipe();
    }
  }
};


// REMOVE SINGLE WEEKLIST ITEM

const removeFromWeekList = (id) => {
    const filteredList = weekList.filter((recipeId) => recipeId !== id);
  setWeekList(filteredList);
  setDoc(weekListRef, { recipes: { arrayValue: { values: filteredList } } }, { merge: true });
};


  return (
    <div className=" App" >

    <div className="bg-homeBg bg-no-repeat bg-cover block w-screen h-[25vh] absolute -z-5 opacity-70" />

      <nav className="relative container p-5 mt-[2vh] mx-auto flex">
        <h1 className="text-3xl font-bold tracking-tighter leading-7">Wat eten we deze week?</h1>
        <button onClick={() => setPopupActive(!popupActive)}>
      Add recipe</button>

      <button onClick={() => setWeekListPopupActive(!weekListPopupActive)}>Week list</button>
      </nav>
      

        <div class="w-3/4 h-[56px] bg-gray-100 rounded-[12px] shadow-lg text-xl flex items-center justify-center py-1 space-x-2 hover:cursor-pointer px-8">
    <input 
    type="text" 
    placeholder="Search" 
    class="bg-gray-100 w-3/4 h-3/4 focus:outline-none flex-1" />
    
    <button type="submit" class="rounded-full flex justify-center items-center">
      <svg xmlns="http://www.w3.org/2000/svg" class="text-gray-700 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
</svg>
    </button>
  </div>

      
      
      <div className="recipeContainer mx-auto container 
      grid grid-cols-2 gap-6 md:grid-cols-2 lg:grid-cols-4">
      <Recipe 
      recipes={recipes} 
      handleView={handleView} 
      removeRecipe={removeRecipe} 
      addToWeekList={addToWeekList}
      editRecipe={editRecipe}
      handleEditSubmit={handleEditSubmit} />
      </div>


      <WeekList 
      weekListPopupActive={weekListPopupActive}
      recipes={recipes}
      weekList={weekList}
      removeFromWeekList={removeFromWeekList}
      addRandomRecipe={addRandomRecipe}
      clearWeekList={clearWeekList}
      generateIngredients={generateIngredients}
      />

      

      { popupActive && <div className="popup">
        <div className="popup-inner absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">

          <RecipeForm
          form={form}
          setForm={setForm}
          handleSubmit={handleSubmit} 
          handleIngredient={handleIngredient} 
          handleIngredientCount={handleIngredientCount} 
          handleStep={handleStep} 
          handleStepCount={handleStepCount} 
          setPopupActive={setPopupActive} 
          resetForm={resetForm} 
          handleEditSubmit={handleEditSubmit}
          handleImageChange={handleImageChange}
           />


          

          {/* JSON.stringify(form) */}

        </div>
      </div>
      }

    </div>
  );
}

export default App;


