import React from 'react'

const WeekList = ({
	weekListPopupActive,
      recipes,
      weekList,
      removeFromWeekList,
      addRandomRecipe,
      clearWeekList,
      generateIngredients
}) => {
	return (
		<>
{ weekListPopupActive && 

      <div className="absolute flex flex-col justify-center popup-inner absolute top-1/2 
      left-1/2 transform -translate-x-1/2 -translate-y-1/2
      fixed inset-0 z-10 overflow-y-auto bg-blue-600">

      <div className="flex items-end justify-center  px-4 pt-4 pb-20 text-center sm:block sm:p-0">
      
            <h2 className="text-lg font-semibold text-gray-700 capitalize dark:text-white">Week List</h2>
<ul>
  {weekList.map((id) => {
    const recipe = recipes.find((r) => r.id === id);
    return (
      <li key={id}>
        {recipe ? (
          <div>
            <h3>{recipe.title}</h3>
            <p>{recipe.description}</p>
          </div>
        ) : (
          <span>Recipe not found</span>
        )}
        <button onClick={() => removeFromWeekList(id)}>🚫</button>
      </li>
    );
  })}
  <button onClick={addRandomRecipe}
  className="w-full px-4 py-2 mt-2 text-sm font-medium tracking-wide 
  text-white capitalize transition-colors duration-300 transform bg-blue-600 
  rounded-md sm:w-auto sm:mt-0 hover:bg-blue-500 
  focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40">add random 🔄</button>
  <button onClick={clearWeekList}
  className="w-full px-4 py-2 mt-2 text-sm font-medium tracking-wide text-gray-700 capitalize transition-colors 
  duration-300 transform border border-gray-200 rounded-md sm:mt-0 sm:w-auto sm:mx-2 dark:text-gray-200 dark:border-gray-700 
  dark:hover:bg-gray-800 hover:bg-gray-100 focus:outline-none focus:ring focus:ring-gray-300 focus:ring-opacity-40">Maak leeg</button>
  <button onClick={generateIngredients}>Genereer Ingrediënten</button>
  <div id="resultio" />
</ul>

      </div>
      </div>

    }
    </>
	)
}

export default WeekList