import React from 'react'
import styles from '../css/main.css'

const Recipe = ({recipes, handleView, removeRecipe, addToWeekList, editRecipe, handleEditSubmit}) => {
	return (
      <>
        { recipes.map((recipe, i) => (
          <div className="singleRecipe max-w-xs overflow-hidden bg-white rounded-lg shadow-lg dark:bg-gray-800" key={recipe.id}>
          <div className="px-4 py-2 bg-white">
          	<h3 className="text-xl font-bold text-gray-800 dark:text-gray cursor-pointer" onClick={() => handleView(recipe.id)}>{recipe.title}</h3>
          	<p className="mt-1 text-sm text-gray-600 dark:text-gray-400" dangerouslySetInnerHTML={{ __html: recipe.desc}}></p>
          </div>
            
           <img className="object-cover w-full h-48 mt-2" src={recipe.imageUrl ? recipe.imageUrl : 'https://via.placeholder.com/350' } />
           
           <div className="flex items-end flex-col px-4 py-2 bg-gray-900">
           	<button className="px-2 py-1 text-xs font-semibold text-gray-900 uppercase transition-colors duration-300 
           	transform bg-white rounded hover:bg-gray-200 focus:bg-gray-400 focus:outline-none" 
           	onClick={() => addToWeekList(recipe.id)} >Add to weeklist</button>
           </div> 
            
           { recipe.viewing && <div>
              <h4 className="bg-gray-200 overflow-hidden flex items-center justify-center absolute">Ingredients</h4>
              <ul>
                {recipe.ingredients.map((ingredient, i) => (
                  <li key={i}>{ingredient}</li>
                  ))}
              </ul>

              <h4>Steps</h4>
              <ol>
              { recipe.steps.map((step, i) => (
                <li key={i}>{step}</li>
                ))}
              </ol>
              <button onClick={() => editRecipe(recipe.id)}>Edit</button>
              <button className="remove" onClick={() => removeRecipe(recipe.id)}>Remove</button>
              <button className="hover:text-darkGrayishBlue bg-brightRed rounded" onClick={() => handleView(recipe.id)}>View { recipe.viewing ? 'less' : 'more'}</button>
          </div> }

            <div className="buttons">
              
              
            </div>

          </div>
        ))}

      </>
	)
}

export default Recipe