// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';


const firebaseConfig = {
  apiKey: "AIzaSyCg5eOJwTNLnjLXtp6q8iTQ7GboOQ3NRx4",
  authDomain: "eetwat-231d7.firebaseapp.com",
  projectId: "eetwat-231d7",
  storageBucket: "eetwat-231d7.appspot.com",
  messagingSenderId: "999915416032",
  appId: "1:999915416032:web:a579954adfa702c0fda385"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const db = getFirestore(app);


export { db }